import React, { useState } from "react"
import { AnimatedLink, AnimatedSection } from "@components/shared"

import {
  attention__body,
  attention__body__container,
  attention__body__description,
  attention__body__title,
  attention__body__learn__more,
  attention__body__subtitle,
} from "./styles/attention.module.scss"

const HomeAttentionSection = () => {
  const [learn, set] = useState(false)
  return (
    <div className={attention__body}>
      <div className={attention__body__container}>
        <section className={attention__body__description}>
          <h2 className={attention__body__title}>
            Z nami Twoje finanse są bezpieczne
          </h2>

          <h4 className={attention__body__subtitle}>
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et"
            <p>Lorem ipsum</p>
          </h4>
        </section>
        <AnimatedLink className={attention__body__learn__more}>
          <button>przeczytaj więcej</button>
        </AnimatedLink>
      </div>
    </div>
  )
}

export default HomeAttentionSection
