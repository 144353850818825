import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Reviews from "./Reviews"

import {
  body__main,
  body__wrapper,
  body__description,
  body__title,
  body__subtitle,
} from "./styles/home.module.scss"

const HomeSection = () => {
  // const { WP } = useStaticQuery(graphql`
  //   {
  //     WP {
  //       pageBy(uri: "home") {
  //         ACF_home {
  //           content
  //         }
  //       }
  //     }
  //   }
  // `)

  // const { content } = WP.pageBy.ACF_home
  return (
    <div className={body__main}>
      <div className={body__wrapper}>
        <section className={body__description}>
          <h2 className={body__title}>Nasi klienci o nas</h2>

          <div className={body__subtitle}>
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et"
          </div>
        </section>
      </div>
    </div>
  )
}

export default HomeSection
