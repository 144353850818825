import React from "react"
import { WPApi } from "@api"
import { TextInput, AnimatedButton, AnimatedLink } from "@components/shared"
import { FlexRow, Parallax } from "@components/layout"
import is from "is_js"

import {
  wrapper,
  header,
  header__title,
  header__subtitle,
  form,
  form__input,
  form__usermsg,
  form__check,
  form__button,
  form__msg,
  form__select,
  link__more,
} from "./styles/contact.module.scss"

class ContactForm extends React.Component {
  state = {
    email: "",
    subject: "",
    msg: "",
    imBusy: false,
  }

  action = () => {
    if (is.email(this.state.email) === true) {
      this.setState({
        imBusy: true,
      })
      WPApi.sendContactForm(
        this.state.email,
        this.state.names,
        this.state.subject,
        this.state.phone
      ).then(({ message }) => {
        this.setState({
          names: "",
          email: "",
          phone: "",
          usermsg: "",
          subject: "",
          msg: message,
          imBusy: false,
        })
      })
    }
  }

  render() {
    if (this.state.imBusy) {
      return (
        <div>
          <h6>Wysyłam Twoją wiadomość</h6>
        </div>
      )
    }
    if (this.state.msg != "") {
      return (
        <div>
          <h6>{this.state.msg}</h6>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className={wrapper}>
          <div className={header}>
            <h3 className={header__title}>Zapraszamy do kontaktu</h3>
          </div>
          <div className={form}>
            <TextInput
              className={form__input}
              type="text"
              value={this.state.names}
              placeholder="Imię i nazwisko"
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  names: value,
                })
              }}
            />
            <TextInput
              className={form__input}
              type="email"
              value={this.state.email}
              placeholder="Twój email"
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  email: value,
                })
              }}
            />
            <TextInput
              className={form__input}
              type="text"
              value={this.state.phone}
              placeholder="Numer telefonu"
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  phone: value,
                })
              }}
            />
            <TextInput
              className={form__input}
              type="text"
              value={this.state.subject}
              placeholder="Temat wiadomości"
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  subject: value,
                })
              }}
            />

            <textarea
              className={form__usermsg}
              type="text"
              value={this.state.usermsg}
              placeholder="Treść wiadomości..."
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  usermsg: value,
                })
              }}
            ></textarea>

            <label>
              <input
                className={form__check}
                type="checkbox"
                placeholder="Temat wiadomości"
              />
              Wyrażamm zgodę na przetwarzanie danych.
            </label>
            {/* <div className={form__select}>
            <select
              value={this.state.subject}
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  subject: value,
                })
              }}
            >
              <option value="" disabled selected hidden>
                Wybierz powód kontaktu
              </option>
              <option value="Poprawienie CV">Poprawienie CV</option>
              <option value="Współpraca biznesowa">Współpraca biznesowa</option>
              <option value="Trening z mentorem">Trening z mentorem</option>
              <option value="Gladiatorzy Javascriptu">
                Gladiatorzy Javascriptu
              </option>
            </select>
          </div> */}
            <AnimatedButton className={form__button} onClick={this.action}>
              Wyślij
              <i className="icofont-double-right"></i>
            </AnimatedButton>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ContactForm
