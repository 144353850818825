import React from "react"
import { Layout, FlexCol, Parallax } from "@components/layout"
import { Breadcrumbs, ResizedImage as Image } from "@components/shared"
import { ContactForm } from "@components/standard"
import contactIcon from "./img/digital2.png"

import {
  page__wrapper,
  page__header,
  page__content,
  contact,
  contact__header,
  contact__links,
  contact__links__single,
  contact__icon,
} from "./styles/contact.module.scss"

const Contact = ({ pageContext }) => {
  const { page, products } = pageContext

  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      {/* <h4>Zawsze warto pogadać</h4> */}

      <div className={page__wrapper}>
        <Breadcrumbs
          elements={[
            {
              label: page.title,
              url: `/${page.slug}/`,
            },
          ]}
        />
        <div className={page__content}>
          <div className={contact}>
            <h5 className={contact__header}>Skontaktuj się ze mną:</h5>
            <ul>
              <li>
                <strong>Jeśli szukasz .....</strong>
              </li>
              <li>
                <strong>Jeśli szukasz ....</strong>
              </li>
              <li>
                <strong>Jeśli nie masz pomysłów .....</strong>
              </li>
              <li>
                <strong>Jeśli .....</strong>
              </li>
            </ul>

            {/* <h5 className={contact__header}>
              Czasem jedno usłyszane zdanie od mentora otwiera Ci bramkę w
              głowie i pozwala zrozumieć temat lepiej niż czytając z
              dokumentacji.
            </h5> */}
          </div>
          <div className={contact__icon}>
            <img src={contactIcon} alt="" />
          </div>
        </div>
        <div className={contact__links}>
          <div className={contact__links__single}>
            <i class="fab fa-facebook-square"></i>
            <h5>
              <a target="_blank" href="https://www.facebook.com/localhost40310">
                {" "}
                Facebook
              </a>
            </h5>
          </div>

          <div className={contact__links__single}>
            <i class="far fa-envelope"></i>
            <h5>
              <a href="mailto:contact@localhost-group.com"> mail</a>
            </h5>
          </div>

          <div className={contact__links__single}>
            <i class="fas fa-mobile-alt"></i>
            <h5>
              <a href="tel:+48000000000">(+48) 000 000 000</a>
            </h5>
          </div>
        </div>
        <ContactForm />
      </div>
    </Layout>
  )
}

export default Contact
