import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Reviews from "./Reviews"
import { AnimatedLink as Animated, AnimatedSection } from "@components/shared"
import books from "./img/contractE.png"

import {
  offer__main,
  offer__wrapper,
  offer__description,
  offer__title,
  offer__offers,
  offer__singleitem,
} from "./styles/offer.module.scss"

const HomeOffer = () => {
  return (
    <div className={offer__main}>
      <div className={offer__wrapper}>
        <section className={offer__description}>
          <h2 className={offer__title}>Co oferujemy?</h2>
          <section className={offer__offers}>
            <Animated className={offer__singleitem}>
              <img src={books} alt="" />
              <div>
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Temporibus doloremque officia ab vel aspernatur animi adipisci
                  repellat fugit porro recusandae!
                </p>
              </div>
            </Animated>

            <Animated className={offer__singleitem}>
              <img src={books} alt="" />
              <div>
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Temporibus doloremque officia ab vel aspernatur animi adipisci
                  repellat fugit porro recusandae!
                </p>
              </div>
            </Animated>
            <Animated className={offer__singleitem}>
              <img src={books} alt="" />
              <div>
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Temporibus doloremque officia ab vel aspernatur animi adipisci
                  repellat fugit porro recusandae!
                </p>
              </div>
            </Animated>
            <Animated className={offer__singleitem}>
              <img src={books} alt="" />
              <div>
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Temporibus doloremque officia ab vel aspernatur animi adipisci
                  repellat fugit porro recusandae!
                </p>
              </div>
            </Animated>
            <Animated className={offer__singleitem}>
              <img src={books} alt="" />
              <div>
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Temporibus doloremque officia ab vel aspernatur animi adipisci
                  repellat fugit porro recusandae!
                </p>
              </div>
            </Animated>
          </section>
        </section>
      </div>
    </div>
  )
}

export default HomeOffer
