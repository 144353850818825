import React, { useState } from "react"
import { AnimatedLink, AnimatedSection } from "@components/shared"

import {
  info__body,
  info__body__container,
  info__body__description,
  info__body__title,
  info__body__data,
  info__body__subtitle,
} from "./styles/info.module.scss"

const HomeInfoSection = () => {
  const [learn, set] = useState(false)
  return (
    <div className={info__body}>
      <div className={info__body__container}>
        <section className={info__body__description}>
          <h2 className={info__body__title}>Dlaczego my?</h2>

          <h4 className={info__body__subtitle}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna
          </h4>
          <div className={info__body__data}>
            <div>
              1000+ <p>Liczba obsłużonych klientów</p>
            </div>
            <div>
              20 <p>Lat obecności na rynku</p>
            </div>
            <div>
              100 <p>Liczba spółek założonych dla naszych klientów</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default HomeInfoSection
